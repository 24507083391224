import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCurrencies,
  selectCurrencies,
  selectOffset,
  selectCount,
  setOffset,
  setActiveFrom,
  setActiveTo,
  searchCurrencies,
  calculateGet,
  selectActiveFrom,
  selectActiveTo,
  selectSend,
  selectBuySellMode,
  fetchFiatCurrencies,
  selectIsBuy,
  selectFiat,
  searchFiatCurrencies,
} from "../../features/currencySlice";

const SelectCurrency = ({ fnc, isTo }) => {
  const currencies = useSelector(selectCurrencies);
  const fiatCurrencies = useSelector(selectFiat);
  const containerRef = useRef(null);
  const offset = useSelector(selectOffset);
  const count = useSelector(selectCount);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const activeFrom = useSelector(selectActiveFrom);
  const activeTo = useSelector(selectActiveTo);
  const send = useSelector(selectSend);
  const isBuySellMode = useSelector(selectBuySellMode);
  const isBuy = useSelector(selectIsBuy);

  const loadMoreCurrencies = () => {
    if (offset + 30 < count) {
      if (isBuySellMode) {
        dispatch(fetchFiatCurrencies({ offset: offset + 30, search }));
      } else {
        dispatch(fetchCurrencies({ offset: offset + 30, search }));
      }
    }
  };

  const handleScroll = () => {
    // Check if the user has scrolled to the bottom of the container
    if (
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
      containerRef.current.scrollHeight
    ) {
      loadMoreCurrencies();
    }
  };
  return (
    <div className="styles__Container-sc-e7jsy4-0 jHvmeI main-page__dropdown-list">
      <div className="styles__Heading-sc-e7jsy4-1 jxbFcQ">
        <div className="styles__SearchIconRow-sc-e7jsy4-3 dnENhI">
          <div className="styles__SearchIcon-sc-e7jsy4-4 gqIbxo" />
        </div>
        <div className="styles__HeadingRow-sc-e7jsy4-5 jLToTC">
          <input
            onChange={(e) => {
              setSearch(e.target.value);
              console.log(e.target.value);
              if(!isBuySellMode || isBuySellMode && isBuy && isTo) {
                dispatch(searchCurrencies({ offset, search: e.target.value }));
              } else {
                dispatch(searchFiatCurrencies({ offset, search: e.target.value }));
              }
            }}
            value={search}
            className="styles__TextInput-sc-e7jsy4-6 liyZNO currency-list__search-field"
            placeholder="Type a cryptocurrency or ticker"
          />
          <div
            onClick={() => fnc(false)}
            role="button"
            className="styles__CloseIcon-sc-e7jsy4-7 ifkIHm"
          />
        </div>
      </div>
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className="styles__Body-sc-e7jsy4-2 pjCoH"
      >
        {!isBuySellMode && !isBuy ? (
          <div className="styles__Row-sc-3z19xm-0 cbvCfa">
            <div className="styles__Item-sc-3z19xm-1 cLhozl">All</div>
            <div className="styles__Item-sc-3z19xm-1 eJkAND">New</div>
            <div className="styles__Item-sc-3z19xm-1 eJkAND">Gainers 24h</div>
            <div className="styles__Item-sc-3z19xm-1 eJkAND">Losers 24h</div>
            <div className="styles__Item-sc-3z19xm-1 eJkAND">Top Stable</div>
          </div>
        ) : ""}
        <div className="styles__BodySectionRow-sc-e7jsy4-9 dsGkYn">
          <p className="styles__BodySectionTitle-sc-e7jsy4-10 kWwOXq">
            All cryptocurrencies
          </p>
        </div>
        {!isBuySellMode && currencies.map((currency) => {
          return (
            <div
              onClick={async () => {
                if (isTo) {
                  dispatch(setActiveTo(currency));
                  dispatch(calculateGet());
                } else {
                  dispatch(setActiveFrom(currency));
                  dispatch(calculateGet());
                }
                fnc(false);
              }}
              className="styles__Container-sc-1s77arm-0 fnvsDI currency-list__item"
              role="close-dropdown"
            >
              <div className="styles__LeftSide-sc-1s77arm-5 kMOOgS">
                <div
                  role="close-dropdown"
                  className="styles__LogoRow-sc-1s77arm-1 haWDeK"
                >
                  <img
                    alt={currency.cmcTicker}
                    src={currency?.img}
                    className="styles__StyledImage-sc-mte8rl-0 kvIvJC"
                  />
                </div>
                <p
                  role="close-dropdown"
                  className="styles__CoinTicker-sc-1s77arm-2 eBafF"
                >
                  {currency.cmcTicker.toUpperCase()}
                </p>
                {currency.network && (
                  <p
                    role="close-dropdown"
                    className="styles__CoinLabel-sc-1s77arm-3 jeOwEp"
                  >
                    {currency.network}
                  </p>
                )}
              </div>
              <div
                role="close-dropdown"
                className="styles__CoinName-sc-1s77arm-4 iGZUzF"
              >
                {currency.name}
              </div>
            </div>
          );
        })}
        {isBuySellMode && isBuy === true && isTo && currencies.map((currency) => {
          return (
            <div
              onClick={async () => {
                if (isTo) {
                  dispatch(setActiveTo(currency));
                  dispatch(calculateGet());
                } else {
                  dispatch(setActiveFrom(currency));
                  dispatch(calculateGet());
                }
                fnc(false);
              }}
              className="styles__Container-sc-1s77arm-0 fnvsDI currency-list__item"
              role="close-dropdown"
            >
              <div className="styles__LeftSide-sc-1s77arm-5 kMOOgS">
                <div
                  role="close-dropdown"
                  className="styles__LogoRow-sc-1s77arm-1 haWDeK"
                >
                  <img
                    alt={currency.cmcTicker}
                    src={currency?.img}
                    className="styles__StyledImage-sc-mte8rl-0 kvIvJC"
                  />
                </div>
                <p
                  role="close-dropdown"
                  className="styles__CoinTicker-sc-1s77arm-2 eBafF"
                >
                  {currency.cmcTicker.toUpperCase()}
                </p>
                {currency.network && (
                  <p
                    role="close-dropdown"
                    className="styles__CoinLabel-sc-1s77arm-3 jeOwEp"
                  >
                    {currency.network}
                  </p>
                )}
              </div>
              <div
                role="close-dropdown"
                className="styles__CoinName-sc-1s77arm-4 iGZUzF"
              >
                {currency.name}
              </div>
            </div>
          );
        })}
        {isBuySellMode && isBuy === false && !isTo && currencies.map((currency) => {
          return (
            <div
              onClick={async () => {
                if (isTo) {
                  dispatch(setActiveTo(currency));
                  dispatch(calculateGet());
                } else {
                  dispatch(setActiveFrom(currency));
                  dispatch(calculateGet());
                }
                fnc(false);
              }}
              className="styles__Container-sc-1s77arm-0 fnvsDI currency-list__item"
              role="close-dropdown"
            >
              <div className="styles__LeftSide-sc-1s77arm-5 kMOOgS">
                <div
                  role="close-dropdown"
                  className="styles__LogoRow-sc-1s77arm-1 haWDeK"
                >
                  <img
                    alt={currency.cmcTicker}
                    src={currency?.img}
                    className="styles__StyledImage-sc-mte8rl-0 kvIvJC"
                  />
                </div>
                <p
                  role="close-dropdown"
                  className="styles__CoinTicker-sc-1s77arm-2 eBafF"
                >
                  {currency.cmcTicker.toUpperCase()}
                </p>
                {currency.network && (
                  <p
                    role="close-dropdown"
                    className="styles__CoinLabel-sc-1s77arm-3 jeOwEp"
                  >
                    {currency.network}
                  </p>
                )}
              </div>
              <div
                role="close-dropdown"
                className="styles__CoinName-sc-1s77arm-4 iGZUzF"
              >
                {currency.name}
              </div>
            </div>
          );
        })}
        {isBuySellMode && isBuy === true && !isTo && fiatCurrencies.map((currency) => {
          return (
            <div
              onClick={async () => {
                if (isTo) {
                  dispatch(setActiveTo(currency));
                  dispatch(calculateGet());
                } else {
                  dispatch(setActiveFrom(currency));
                  dispatch(calculateGet());
                }
                fnc(false);
              }}
              className="styles__Container-sc-1s77arm-0 fnvsDI currency-list__item"
              role="close-dropdown"
            >
              <div className="styles__LeftSide-sc-1s77arm-5 kMOOgS">
                <div
                  role="close-dropdown"
                  className="styles__LogoRow-sc-1s77arm-1 haWDeK"
                >
                  <img
                    alt={currency.cmcTicker}
                    src={currency?.img}
                    className="styles__StyledImage-sc-mte8rl-0 kvIvJC"
                  />
                </div>
                <p
                  role="close-dropdown"
                  className="styles__CoinTicker-sc-1s77arm-2 eBafF"
                >
                  {currency.cmcTicker.toUpperCase()}
                </p>
                {currency.network && (
                  <p
                    role="close-dropdown"
                    className="styles__CoinLabel-sc-1s77arm-3 jeOwEp"
                  >
                    {currency.network}
                  </p>
                )}
              </div>
              <div
                role="close-dropdown"
                className="styles__CoinName-sc-1s77arm-4 iGZUzF"
              >
                {currency.name}
              </div>
            </div>
          );
        })}
        {isBuySellMode && isBuy === false && isTo && fiatCurrencies.map((currency) => {
          return (
            <div
              onClick={async () => {
                if (isTo) {
                  dispatch(setActiveTo(currency));
                  dispatch(calculateGet());
                } else {
                  dispatch(setActiveFrom(currency));
                  dispatch(calculateGet());
                }
                fnc(false);
              }}
              className="styles__Container-sc-1s77arm-0 fnvsDI currency-list__item"
              role="close-dropdown"
            >
              <div className="styles__LeftSide-sc-1s77arm-5 kMOOgS">
                <div
                  role="close-dropdown"
                  className="styles__LogoRow-sc-1s77arm-1 haWDeK"
                >
                  <img
                    alt={currency.cmcTicker}
                    src={currency?.img}
                    className="styles__StyledImage-sc-mte8rl-0 kvIvJC"
                  />
                </div>
                <p
                  role="close-dropdown"
                  className="styles__CoinTicker-sc-1s77arm-2 eBafF"
                >
                  {currency.cmcTicker.toUpperCase()}
                </p>
                {currency.network && (
                  <p
                    role="close-dropdown"
                    className="styles__CoinLabel-sc-1s77arm-3 jeOwEp"
                  >
                    {currency.network}
                  </p>
                )}
              </div>
              <div
                role="close-dropdown"
                className="styles__CoinName-sc-1s77arm-4 iGZUzF"
              >
                {currency.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectCurrency;
