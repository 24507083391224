import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    recipentAddress: "",
    depositAddress: ""
}

const exchangeSlice = createSlice({
    name: 'exchange',
    initialState,
    reducers: {
        setDepositAddress(state, action) {
            state.depositAddress = action.payload
        },
        setRecipentAddress(state, action) {
            state.recipentAddress = action.payload
        },
    },
})

export const { setDepositAddress, setRecipentAddress } = exchangeSlice.actions
export const selectDepositAddress = (state) => state.exchange.depositAddress;
export const selectRecipentAddress = (state) => state.exchange.recipentAddress;
export default exchangeSlice.reducer