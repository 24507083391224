import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../Api/axios";
// Загрузка списка валют
export const fetchCurrencies = createAsyncThunk(
  "currencies/fetchCurrencies",
  async ({ offset, search }) => {
    try {
      const { data } = await API.get(
        `/currencies/list?includeDisabled=false&extended=false&limit=30&isFiat=false&isFixed=false&offset=${offset}&search=${search}`,
      );
      console.log("data", data);

      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

export const fetchFiatCurrencies = createAsyncThunk(
  "currencies/fetchFiatCurrencies",
  async ({ offset, search }) => {
    const { data } = await API.get(
      `/currencies/list?includeDisabled=false&extended=false&limit=30&isFiat=true&isFixed=false&offset=${offset}&search=${search}`
    );
    return data;
  }
);

// Загрузка списка валют
export const searchFiatCurrencies = createAsyncThunk(
  "currencies/searchFiatCurrencies",
  async ({ offset, search }) => {
    const { data } = await API.get(
      `/currencies/list?includeDisabled=false&extended=false&limit=30&isFiat=true&isFixed=false&offset=${offset}&search=${search}`
    );
    return data;
  }
);

// Загрузка списка валют
export const searchCurrencies = createAsyncThunk(
  "currencies/searchCurrencies",
  async ({ offset, search }) => {
    const { data } = await API.get(
      `/currencies/list?includeDisabled=false&extended=false&limit=30&isFiat=false&isFixed=false&offset=${offset}&search=${search}`
    );
    return data;
  }
);

// Загрузка списка валют
export const calculateGet = createAsyncThunk(
  "currencies/calculateGet",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const { data } = await API.get(
      `/estimates?currencyFrom=${state.currencies.activeFrom.symbol}&currencyTo=${state.currencies.activeTo.symbol}&amount=${state.currencies.send}&fixed=false&site=true`
    );
    return data.result;
  }
);

// Инициализационное состояние
const initialState = {
  currencies: [],
  fiatCurrencies: [],
  activeFrom: {
    isFavorite: false,
    dailyChange: "0.16451973",
    symbol: "btc",
    cmcTicker: "btc",
    name: "Bitcoin",
    network: null,
    symbolFront: "btc",
    validationAddress:
      "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$",
    validationExtra: null,
    extraId: null,
    hasExtraId: false,
    isFiat: false,
    priority: true,
    priorityFrom: 1250,
    priorityTo: 1137,
    warningsFrom: [],
    warningsTo: [],
    img: "https://static.simpleswap.io/images/currencies-logo/btc.svg",
  },
  activeTo: {
    isFavorite: false,
    dailyChange: "-0.87979743",
    symbol: "eth",
    cmcTicker: "eth",
    name: "Ethereum",
    network: null,
    symbolFront: "eth",
    validationAddress: "^(0x)[0-9A-Fa-f]{40}$",
    validationExtra: null,
    extraId: null,
    hasExtraId: false,
    isFiat: false,
    priority: true,
    priorityFrom: 1249,
    priorityTo: 1139,
    warningsFrom: [],
    warningsTo: [],
    img: "https://static.simpleswap.io/images/currencies-logo/eth.svg",
  },
  loading: false,
  error: null,
  offset: 0,
  count: null,
  send: "0.1",
  get: "",
  min: null,
  max: null,
  isBuySellMode: false,
  isBuy: true,
};

// Slice для управления валютами
const currencySlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {
    setActiveFrom(state, action) {
      state.activeFrom = action.payload;
    },
    setActiveTo(state, action) {
      state.activeTo = action.payload;
    },
    setOffset(state, action) {
      state.offset = action.payload;
    },
    setSwap(state, action) {
      const from = state.activeFrom;
      const to = state.activeTo;

      state.activeFrom = to;
      state.activeTo = from;
      state.isBuy = !state.isBuy;
    },
    setSendAmount(state, action) {
      state.send = action.payload;
    },
    setBuySellMode(state, action) {
      state.isBuySellMode = action.payload;
      state.offset = 0;
      state.count = null;

      if (action.payload && state.isBuy) {
        state.activeFrom = state.fiatCurrencies[0];
        state.activeTo = state.currencies[0];
      } else if (!action.payload) {
        state.activeFrom = state.currencies[0];
        state.activeTo = state.fiatCurrencies[1];
      } else if (action.payload && !state.isBuy) {
        state.activeFrom = state.currencies[0];
        state.activeTo = state.fiatCurrencies[0];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        const currenciesToSet = action.payload.result.map((cur) => {
          return {
            ...cur,
            img: `https://static.simpleswap.io/images/currencies-logo/${cur.cmcTicker}.svg`,
          };
        });
        state.currencies = [...state.currencies, ...currenciesToSet];
        state.offset = action.payload.offset;
        state.count = action.payload.count;
      })
      .addCase(fetchCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchCurrencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        const currenciesToSet = action.payload.result.map((cur) => {
          return {
            ...cur,
            img: `https://static.simpleswap.io/images/currencies-logo/${cur.cmcTicker}.svg`,
          };
        });
        state.currencies = currenciesToSet;
        state.offset = action.payload.offset;
        state.count = action.payload.count;
      })
      .addCase(searchCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchFiatCurrencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchFiatCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        const currenciesToSet = action.payload.result.map((cur) => {
          return {
            ...cur,
            img: `https://static.simpleswap.io/images/currencies-logo/${cur.cmcTicker}.svg`,
          };
        });
        state.fiatCurrencies = currenciesToSet;
        state.offset = action.payload.offset;
        state.count = action.payload.count;
      })
      .addCase(searchFiatCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(calculateGet.pending, (state) => {
        state.loading = true;
      })
      .addCase(calculateGet.fulfilled, (state, action) => {
        state.loading = false;
        state.get = action.payload.estimate;
        state.min = action.payload.min;
        state.max = action.payload.max;
      })
      .addCase(calculateGet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.min = null;
        state.max = null;
        state.get = "";
      })
      .addCase(fetchFiatCurrencies.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFiatCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        const currenciesToSet = action.payload.result.map((cur) => {
          return {
            ...cur,
            img: `https://static.simpleswap.io/images/currencies-logo/${cur.cmcTicker}.svg`,
          };
        });
        state.fiatCurrencies = [...state.fiatCurrencies, ...currenciesToSet];
        state.offset = action.payload.offset;
        state.count = action.payload.count;
      })
      .addCase(fetchFiatCurrencies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setActiveFrom,
  setActiveTo,
  setOffset,
  setSwap,
  setSendAmount,
  setBuySellMode,
} = currencySlice.actions;

// Экспортируем селекторы
export const selectCurrencies = (state) => state.currencies.currencies;
export const selectActiveFrom = (state) => state.currencies.activeFrom;
export const selectActiveTo = (state) => state.currencies.activeTo;
export const selectLoading = (state) => state.currencies.loading;
export const selectError = (state) => state.currencies.error;
export const selectOffset = (state) => state.currencies.offset;
export const selectCount = (state) => state.currencies.count;
export const selectSend = (state) => state.currencies.send;
export const selectGet = (state) => state.currencies.get;
export const selectMin = (state) => state.currencies.min;
export const selectMax = (state) => state.currencies.max;
export const selectBuySellMode = (state) => state.currencies.isBuySellMode;
export const selectFiat = (state) => state.currencies.fiatCurrencies;
export const selectIsBuy = (state) => state.currencies.isBuy;

export default currencySlice.reducer;
