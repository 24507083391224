import React, { useEffect, useState } from "react";
import {
  calculateGet,
  fetchCurrencies,
  selectActiveFrom,
  selectActiveTo,
  selectBuySellMode,
  selectCurrencies,
  selectGet,
  selectIsBuy,
  selectLoading,
  selectSend,
  setActiveFrom,
  setActiveTo,
  setSendAmount,
  setSwap,
} from "../../features/currencySlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import SelectCurrency from "../SelectCurrency/SelectCurrency";
import { ReactComponent as Loader } from "../../assets/loading2.svg";
import { useNavigate } from "react-router-dom";
import {
  setDepositAddress,
  setRecipentAddress,
} from "../../features/exchangeSlice";
import { API } from "../../Api/axios";

const RightExchange = () => {
  const [fromVisible, setFromVisible] = useState(false);
  const [toVisible, setToVisible] = useState(false);
  const activeFrom = useSelector(selectActiveFrom);
  const activeTo = useSelector(selectActiveTo);
  const dispatch = useDispatch();
  const send = useSelector(selectSend);
  const get = useSelector(selectGet);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const [validate, setValidate] = useState(false);
  const [address, setAddress] = useState("");
  const [clicked, setClicked] = useState(false);
  const [dollars, setDollars] = useState(false);
  const [isAdditional, setIsAdditional] = useState(false);
  const isBuy = useSelector(selectIsBuy);
  const isBuySellMode = useSelector(selectBuySellMode);

  const caluclateDollars = async () => {
    try {
      const { data } = await API.get(
        `/estimates/estimated/usd?currencyFrom=${activeFrom.cmcTicker}&currencyTo=${activeTo.cmcTicker}&amount=${send}&fixed=false`
      );

      setDollars(data.toFixed(2));
    } catch (error) {}
  };

  useEffect(() => {
    caluclateDollars();
  }, [send, activeFrom, activeTo]);

  const handleAddress = (e) => {
    const regex = new RegExp(activeTo.validationAddress);
    const str = e.target.value;
    setAddress(str);

    if (regex.test(str.trim())) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  };

  useEffect(() => {
    if (!isBuy) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [isBuy]);

  const getTransaction = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/transaction`,
        {
          send,
          getVal: get,
          from: activeFrom,
          to: activeTo,
          recipentAddress: address,
        }
      );

      return data;
    } catch (error) {
      alert("Something went wrong", error);
    }
  };

  // const getWallet = async () => {
  //   try {
  //     const { data } = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/wallets`
  //     );

  //     console.log(activeFrom);

  //     const { wallet } = data.find(
  //       (wallet) => wallet.ticker === activeFrom.symbol
  //     );

  //     console.log(wallet);

  //     return wallet;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    dispatch(fetchCurrencies({ offset: 0, search: "" }));

    dispatch(calculateGet());

    // dispatch(setActiveFrom(countries[0]))
    // dispatch(setActiveTo(countries[1]))
  }, []);

  return (
    <div className="styles__Layout-sc-rmgah-0 itpnrP main-form">
      <h1 className="styles__Title-sc-rmgah-16 kGoYbC">Add exchange details</h1>
      <div className="styles__Fields-sc-14ws4vw-2 iuTKaC">
        <div className="styles__Row-sc-9vm97v-0 kHOViF main-page__exchange-group main-page__exchange-from">
          <div className="styles__InputGroup-sc-9vm97v-2 ieTbuU">
            <label
              htmlFor="exchange_form_from_field"
              className="styles__InputLabel-sc-9vm97v-1 jyfsSR"
            >
              You send
            </label>
            <div className="styles__InputContainer-sc-9vm97v-5 imItqd">
              <input
                value={send}
                onChange={(e) => {
                  dispatch(setSendAmount(e.target.value));
                  dispatch(calculateGet());
                }}
                type="text"
                inputMode="decimal"
                id="exchange_form_from_field"
                className="styles__Input-sc-9vm97v-3 eXBwVq"
              />
              <div className="styles__EstimateContainer-sc-9vm97v-4 foerNv">
                {dollars && `≈$${dollars}`}
              </div>
            </div>
          </div>
          <div
            onClick={() => setFromVisible(true)}
            className="styles__Layout-sc-9vm97v-10 bEszjz"
          >
            <div className="styles__Icon-sc-9vm97v-12 gnOYfL">
              <img
                alt={activeFrom && activeFrom.cmcTicker}
                src={activeFrom && activeFrom.img}
                className="styles__StyledImage-sc-mte8rl-0 bbwTkW"
              />
            </div>
            <span className="styles__Name-sc-9vm97v-13 hDfsRd">
              {activeFrom && activeFrom.cmcTicker.toUpperCase()}
            </span>
            {activeFrom.network ? (
              <div className="styles__Network-sc-9vm97v-14 jmQhnq">
                {activeFrom.network}
              </div>
            ) : (
              ""
            )}
            <div className="styles__Arrow-sc-9vm97v-9 ikkWHY" />
          </div>
          {fromVisible && <SelectCurrency fnc={setFromVisible} />}
        </div>
        <div
          id="form-controls-container"
          className="styles__Controls-sc-1nifg34-0 bBGonL"
        >
          <div className="styles__FloatingRow-sc-1nifg34-3 eVIyoN">
            <div className="styles__FloatingButton-sc-1nifg34-1 elTiF">
              <div className="styles__FloatingIcon-sc-1nifg34-2 jbNTmm main-exchange__floating-icon" />
            </div>
            <div className="styles__FloatingText-sc-1nifg34-4 Ehrph">
              Floating rate
            </div>
          </div>
          <div className="styles__SwapRow-sc-1nifg34-15 dhDeMQ">
            <div
              onClick={() => {
                dispatch(setSwap());
                dispatch(calculateGet());
              }}
              className="styles__SwapIcon-sc-1nifg34-14 cfbzaE"
            />
          </div>
        </div>
        <div className="styles__Row-sc-9vm97v-0 kHOViF main-page__exchange-group main-page__exchange-to">
          <div className="styles__InputGroup-sc-9vm97v-2 jfZjGP">
            {loading ? <Loader /> : ""}
            <label
              htmlFor="exchange_form_to_field"
              className="styles__InputLabel-sc-9vm97v-1 jyfsSR"
            >
              You get
            </label>
            <div className="styles__InputTo-sc-9vm97v-7 gtXlel">
              {loading ? (
                ""
              ) : (
                <div className="styles__InputToTilda-sc-9vm97v-8 dJRMsx" />
              )}
              <span className="styles__InputText-sc-9vm97v-6 hbQfxm">
                {loading ? "" : get}
              </span>
            </div>
          </div>
          <div
            onClick={() => setToVisible(true)}
            className="styles__Layout-sc-9vm97v-10 bEszjz"
          >
            <div className="styles__Icon-sc-9vm97v-12 gnOYfL">
              <img
                alt={activeTo && activeTo.cmcTicker}
                src={activeTo && activeTo.img}
                className="styles__StyledImage-sc-mte8rl-0 bbwTkW"
              />
            </div>
            <span className="styles__Name-sc-9vm97v-13 hDfsRd">
              {activeTo && activeTo.cmcTicker.toUpperCase()}
            </span>
            {activeFrom.network ? (
              <div className="styles__Network-sc-9vm97v-14 jmQhnq">
                {activeFrom.network}
              </div>
            ) : (
              ""
            )}
            <div className="styles__Arrow-sc-9vm97v-9 ikkWHY" />
          </div>
          {toVisible && <SelectCurrency isTo={true} fnc={setToVisible} />}
        </div>
      </div>

      <div className="styles__Layout-sc-yho1ae-0 ktrFoH">
        <div className="styles__Divider-sc-yho1ae-2 cGjqIP" />
        <div className="styles__WrapperSteps-sc-rmgah-1 cxMuOy">
          <div className="styles__Wrapper-sc-11r4b3l-0 bKKEKl">
            <div className="styles__Title-sc-11r4b3l-2 khRXwR">
              How to swap:
            </div>
            <div className="styles__FlexItem-sc-11r4b3l-1 iWRzCv">
              <div color="green" className="styles__Icon-sc-11r4b3l-3 hJmZoW">
                <div className="styles__Check-sc-11r4b3l-4 jTBPDF" />
              </div>
              <div color="green" className="styles__Label-sc-11r4b3l-6 hTibrS">
                Choose currencies
              </div>
            </div>
            <div className="styles__Divider-sc-11r4b3l-5 lhQfbJ" />
            <div className="styles__FlexItem-sc-11r4b3l-1 iWRzCv">
              {address && validate ? (
                <div color="green" className="styles__Icon-sc-11r4b3l-3 hJmZoW">
                  <div className="styles__Check-sc-11r4b3l-4 jTBPDF"></div>
                </div>
              ) : (
                <div color="black" className="styles__Icon-sc-11r4b3l-3 boIVRC">
                  2
                </div>
              )}

              {address && validate ? (
                <div
                  color="green"
                  className="styles__Label-sc-11r4b3l-6 hTibrS"
                >
                  Enter the address
                </div>
              ) : (
                <div
                  color="black"
                  className="styles__Label-sc-11r4b3l-6 byDXUy"
                >
                  Enter the address
                </div>
              )}
            </div>
            <div className="styles__Divider-sc-11r4b3l-5 lhQfbJ" />
            <div className="styles__FlexItem-sc-11r4b3l-1 iWRzCv">
              <div color="gray" className="styles__Icon-sc-11r4b3l-3 kGACCS">
                3
              </div>
              <div color="gray" className="styles__Label-sc-11r4b3l-6 kQzJdi">
                Create an exchange
              </div>
            </div>
          </div>
        </div>
        <div className="styles__Main-sc-yho1ae-3 fDZvvp">
          {!isBuySellMode && (
            <>
              <div className="styles__WalletRow-sc-yho1ae-4 dsLuda">
                <h2 className="styles__WalletTitle-sc-yho1ae-6 degdGC">
                  Enter the wallet address
                </h2>
              </div>
              <div
                className={
                  focused || address.length
                    ? "styles__InputGroup-sc-yho1ae-8 jduwXO"
                    : "styles__InputGroup-sc-yho1ae-8 gEUovM"
                }
              >
                <div className="styles__InputLabel-sc-yho1ae-9 gWvcdy exchange-group__label">
                  The recipient’s {activeTo.name} address
                </div>
                <input
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  value={address}
                  onChange={(e) => handleAddress(e)}
                  id="address-input"
                  className="styles__Input-sc-yho1ae-11 esfEBp address-input"
                  autoComplete="off"
                  spellCheck="false"
                />
                {address.length ? (
                  validate ? (
                    ""
                  ) : (
                    <div className="styles__InputError-sc-yho1ae-10 hprcRZ">
                      Invalid address
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </>
          )}

          {isBuySellMode && isBuy && (
            <>
              <div className="styles__WalletRow-sc-yho1ae-4 dsLuda">
                <h2 className="styles__WalletTitle-sc-yho1ae-6 degdGC">
                  Enter the wallet address
                </h2>
              </div>
              <div
                className={
                  focused || address.length
                    ? "styles__InputGroup-sc-yho1ae-8 jduwXO"
                    : "styles__InputGroup-sc-yho1ae-8 gEUovM"
                }
              >
                <div className="styles__InputLabel-sc-yho1ae-9 gWvcdy exchange-group__label">
                  The recipient’s {activeTo.name} address
                </div>
                <input
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  value={address}
                  onChange={(e) => handleAddress(e)}
                  id="address-input"
                  className="styles__Input-sc-yho1ae-11 esfEBp address-input"
                  autoComplete="off"
                  spellCheck="false"
                />
                {address.length ? (
                  validate ? (
                    ""
                  ) : (
                    <div className="styles__InputError-sc-yho1ae-10 hprcRZ">
                      Invalid address
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </>
          )}
          <div className="styles__ScanerOuter-sc-yho1ae-13 dlxQko">
            <div className="styles__ScanerBack-sc-yho1ae-14 kjLmbH" />
            <div className="styles__ScanerText-sc-yho1ae-15 kvsTJv">
              Place the QR code within the frame
            </div>
          </div>
          <button
            onClick={async () => {
              if (!isBuySellMode) {
                const transaction = await getTransaction();
                setClicked(true);
                setTimeout(() => {
                  navigate(`/exchange/process/${transaction._id}`);
                }, 1500);
              } else {
                navigate(`/exchange/mercurio?isBuy=${isBuy ? '1' : '0'}`)
              }
            }}
            disabled={!validate}
            className="styles__Button-sc-yho1ae-16 jjUgzU"
          >
            <span>
              {clicked ? <Loader fill="#fff" /> : "Create an exchange"}
            </span>
          </button>
          <div className="styles__Agreement-sc-yho1ae-22 lfbBaU">
            By clicking Create an exchange, I agree to the{/* */}{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              className="styles__AgreementLink-sc-yho1ae-23 iPOdrf"
            >
              Privacy Policy
            </a>{" "}
            {/* */}and{/* */}{" "}
            <a
              href="/terms-of-service"
              target="_blank"
              className="styles__AgreementLink-sc-yho1ae-23 iPOdrf"
            >
              Terms of Service
            </a>
            .
          </div>
        </div>
        <div className="styles__Container-sc-1p5navw-0 fmlXeI" />
      </div>
      <div className="styles__RefundRow-sc-rmgah-29 hwmbeD">
        <div
          type="button"
          role="button"
          id="react-collapsed-toggle-1"
          aria-controls="react-collapsed-panel-1"
          aria-expanded="false"
          tabIndex="0"
          className="styles__RefundBtn-sc-rmgah-30 cXfknQ advanced-button"
          onClick={() => setIsAdditional((prev) => !prev)}
        >
          Additional Information
        </div>
      </div>
      {isAdditional && (
        <div
          id="react-collapsed-panel-1"
          aria-hidden="false"
          className="styles__RefundBlock-sc-rmgah-31 cbypZG"
          style={{ boxSizing: "border-box" }}
        >
          <div
            className="styles__RefundContent-sc-rmgah-32 fKYtNU"
            style={{ paddingBottom: 40 }}
          >
            <div className="styles__TextWrapper-sc-rmgah-34 jasYmy">
              <div
                color="#252C44"
                className="sc-jOrMOR iQllog"
                style={{ marginBottom: 4 }}
              >
                Enter the refund address
              </div>
              <div color="#859AB5" className="sc-jOrMOR kcPXMw">
                We recommend adding your wallet address for a refund.
              </div>
            </div>
            <div className="styles__InputWrapper-sc-rmgah-33 kFEkf">
              <div className="sc-breuTD kXxHdY">
                <div className="sc-ksZaOG iEAtcA">
                  <div className="sc-hAZoDl edczHF">
                    <span aria-hidden="true" className="sc-fnykZs deUnks">
                      The {activeFrom.cmcTicker.toUpperCase()} refund address
                    </span>
                    <div className="sc-fEOsli hpbhUD">
                      <span className="sc-bjUoiL fTWDKq">
                        The {activeFrom.cmcTicker.toUpperCase()} refund address
                      </span>
                    </div>
                    <div className="sc-idiyUo kNgHCq">
                      <input
                        type="text"
                        aria-label={`The ${activeFrom.cmcTicker.toUpperCase()} refund address`}
                        className="sc-dmRaPn dLGnfl"
                        defaultValue=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="styles__RefundContent-sc-rmgah-32 fKYtNU"
            style={{ paddingTop: 0 }}
          >
            <div className="styles__TextWrapper-sc-rmgah-34 jasYmy">
              <div
                color="#252C44"
                className="sc-jOrMOR iQllog"
                style={{ marginBottom: 4 }}
              >
                Add Email
              </div>
              <div color="#859AB5" className="sc-jOrMOR kcPXMw">
                If you want to get notifications about this exchange.
              </div>
            </div>
            <div className="styles__InputWrapper-sc-rmgah-33 kFEkf">
              <div className="sc-breuTD kXxHdY">
                <div className="sc-ksZaOG iEAtcA">
                  <div className="sc-hAZoDl edczHF">
                    <span aria-hidden="true" className="sc-fnykZs deUnks">
                      The email address
                    </span>
                    <div className="sc-fEOsli hpbhUD">
                      <span className="sc-bjUoiL fTWDKq">
                        The email address
                      </span>
                    </div>
                    <div className="sc-idiyUo kNgHCq">
                      <input
                        type="text"
                        aria-label="The email address"
                        className="sc-dmRaPn dLGnfl"
                        defaultValue=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="styles__ScanerOuter-sc-yho1ae-13 dlxQko">
            <div className="styles__ScanerBack-sc-yho1ae-14 kjLmbH" />
            <div className="styles__ScanerText-sc-yho1ae-15 kvsTJv">
              Place the QR code within the frame
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightExchange;
