import React from "react";

const HowItWorks = () => {
  return (
    <div className="styles__Background-sc-1l75bjs-0 cYaeDB">
      <div className="styles__Wrapper-sc-1l75bjs-1 fudNjN">
        <div className="styles__GuideButton-sc-1l75bjs-4 egExJp">
          <span className="styles__GuideText-sc-1l75bjs-3 hOZRwO">
            Watch a video guide
          </span>
          <span className="styles__GuideTextMobile-sc-1l75bjs-2 hwBTfa">
            Watch a tutorial
          </span>
          <div className="styles__GuideIcon-sc-1l75bjs-5 fNNoWt">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={14} cy={14} r={14} />
              <path
                d="M18.5 13.134C19.1667 13.5189 19.1667 14.4811 18.5 14.866L12.5 18.3301C11.8333 18.715 11 18.2339 11 17.4641L11 10.5359C11 9.7661 11.8333 9.28497 12.5 9.66987L18.5 13.134Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="styles__Title-sc-1l75bjs-6 eqPrQu">How It Works</div>
        <div className="styles__Wrapper-sc-19sau32-0 gXLcRZ">
          <div className="styles__Card-sc-19sau32-1 gJSzjU">
            <div className="styles__Header-sc-19sau32-2 bLGEnS">
              <div
                color="#23B3F2"
                className="styles__IconWrapper-sc-19sau32-4 brdvN"
              >
                <div className="styles__Fireworks-sc-19sau32-6 cRfId">
                  <div className="lf-player-container">
                    <div
                      id="lottie"
                      style={{
                        background: "transparent",
                        margin: "0 auto",
                        outline: "none",
                        overflow: "hidden",
                        width: 120,
                        height: 120,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 500 500"
                        width={500}
                        height={500}
                        preserveAspectRatio="xMidYMid meet"
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: "translate3d(0px, 0px, 0px)",
                          contentVisibility: "visible",
                        }}
                      >
                        <defs>
                          <clipPath id="__lottie_element_2">
                            <rect width={500} height={500} x={0} y={0} />
                          </clipPath>
                          <clipPath id="__lottie_element_4">
                            <path d="M0,0 L500,0 L500,500 L0,500z" />
                          </clipPath>
                          <clipPath id="__lottie_element_8">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_12">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_16">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_20">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_24">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_28">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_32">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_36">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_40">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_44">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_48">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_52">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_56">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_60">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_64">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_68">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                        </defs>
                        <g clipPath="url(#__lottie_element_2)">
                          <g
                            clipPath="url(#__lottie_element_4)"
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <g
                              clipPath="url(#__lottie_element_8)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_12)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_16)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_20)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_24)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_28)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_32)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_36)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_40)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_44)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_48)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_52)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_56)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_60)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_64)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_68)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI4Ljc1IDE5Ljk2MmE4Ljc0NCA4Ljc0NCAwIDAxLTguNzUgOC43NWwxLjMxMy0yLjE4N00xLjI1IDkuOTYyQTguNzQ0IDguNzQ0IDAgMDExMCAxLjIxMkw4LjY4NyAzLjQiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik04LjI3NSAxNi4zMzdoMy41MTNjLjc3NSAwIDEuNDEyLjcgMS40MTIgMS40MTNhMS40MSAxLjQxIDAgMDEtMS40MTIgMS40MTJIOC4yNzV2LTIuODI1em0wIDIuODI2SDEyLjNjLjg4OCAwIDEuNjEzLjYyNSAxLjYxMyAxLjQxMiAwIC43NzUtLjcyNSAxLjQxMy0xLjYxMyAxLjQxM0g4LjI3NXYtMi44MjV6bTIuMjUgMi44MTJ2MS40bTAtOC40Mzd2MS40IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik0xOC41NjMgMTkuMTYzYTcuNzA5IDcuNzA5IDAgMDEtNy43MTMgNy43MTIgNy43MDkgNy43MDkgMCAwMS03LjcxMi03LjcxMiA3LjcwOSA3LjcwOSAwIDAxNy43MTItNy43MTNjLjIgMCAuMzg4LjAxMy42LjAyNWE3LjcxOCA3LjcxOCAwIDAxNy4xIDcuMWMwIC4xODguMDEzLjM3NS4wMTMuNTg4eiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMjYuODc1IDEwLjgzOGE3LjcwOSA3LjcwOSAwIDAxLTcuNzEyIDcuNzEyaC0uNjEzYTcuNzE4IDcuNzE4IDAgMDAtNy4xLTcuMXYtLjYxMmE3LjcwOSA3LjcwOSAwIDAxNy43MTMtNy43MTMgNy43MDkgNy43MDkgMCAwMTcuNzEyIDcuNzEzeiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4="
                  alt="Currency pair"
                  className="styles__Icon-sc-19sau32-5 fgDaFA"
                />
              </div>
              <div className="styles__LineWrapper-sc-19sau32-7 ePLInh">
                <div
                  color="#23B3F2"
                  className="styles__Line-sc-19sau32-8 cSSJDV"
                />
              </div>
            </div>
            <div className="styles__Content-sc-19sau32-3 eDClTO">
              <div className="styles__Title-sc-19sau32-9 dHgIqu">
                Choose a currency pair
              </div>
              <div className="styles__Description-sc-19sau32-10 ilGpRL">
                Select currencies you want to swap and click the Exchange
                button.
              </div>
            </div>
          </div>
          <div className="styles__Card-sc-19sau32-1 gJSzjU">
            <div className="styles__Header-sc-19sau32-2 bLGEnS">
              <div
                color="#3993FF"
                className="styles__IconWrapper-sc-19sau32-4 jQsHNJ"
              >
                <div className="styles__Fireworks-sc-19sau32-6 cRfId">
                  <div className="lf-player-container">
                    <div
                      id="lottie"
                      style={{
                        background: "transparent",
                        margin: "0 auto",
                        outline: "none",
                        overflow: "hidden",
                        width: 120,
                        height: 120,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 500 500"
                        width={500}
                        height={500}
                        preserveAspectRatio="xMidYMid meet"
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: "translate3d(0px, 0px, 0px)",
                          contentVisibility: "visible",
                        }}
                      >
                        <defs>
                          <clipPath id="__lottie_element_120">
                            <rect width={500} height={500} x={0} y={0} />
                          </clipPath>
                          <clipPath id="__lottie_element_122">
                            <path d="M0,0 L500,0 L500,500 L0,500z" />
                          </clipPath>
                          <clipPath id="__lottie_element_126">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_130">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_134">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_138">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_142">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_146">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_150">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_154">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_158">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_162">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_166">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_170">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_174">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_178">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_182">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_186">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                        </defs>
                        <g clipPath="url(#__lottie_element_120)">
                          <g
                            clipPath="url(#__lottie_element_122)"
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <g
                              clipPath="url(#__lottie_element_126)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_130)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_134)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_138)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_142)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_146)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_150)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_154)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_158)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_162)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_166)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_170)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_174)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_178)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_182)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_186)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2LjI1IDExLjI1aC03LjVtMTguNzUgMi40NjJ2Mi41NzZjMCAuNjg3LS41NSAxLjI1LTEuMjUgMS4yNzRIMjMuOGMtMS4zNSAwLTIuNTg3LS45ODctMi43LTIuMzM3YTIuNTEgMi41MSAwIDAxLjc1LTIuMDM3Yy40NjMtLjQ3NiAxLjEtLjc1IDEuOC0uNzVoMi42Yy43LjAyNCAxLjI1LjU4NyAxLjI1IDEuMjc0eiIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTIxLjg1IDEzLjE4OGEyLjUxIDIuNTEgMCAwMC0uNzUgMi4wMzdjLjExMiAxLjM1IDEuMzUgMi4zMzggMi43IDIuMzM4aDIuNDV2MS44MTJjMCAzLjc1LTIuNSA2LjI1LTYuMjUgNi4yNUg4Ljc1Yy0zLjc1IDAtNi4yNS0yLjUtNi4yNS02LjI1di04Ljc1YzAtMy40IDIuMDUtNS43NzUgNS4yMzctNi4xNzVhNi42NiA2LjY2IDAgMDExLjAxMy0uMDc1SDIwYy4zMjUgMCAuNjM3LjAxMi45MzguMDYyIDMuMjI1LjM3NSA1LjMxMiAyLjc2MyA1LjMxMiA2LjE4OHYxLjgxM2gtMi42Yy0uNyAwLTEuMzM3LjI3NC0xLjguNzV6IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4="
                  alt="Currency pair"
                  className="styles__Icon-sc-19sau32-5 fgDaFA"
                />
              </div>
              <div className="styles__LineWrapper-sc-19sau32-7 ePLInh">
                <div
                  color="#3993FF"
                  className="styles__Line-sc-19sau32-8 fQKMJl"
                />
              </div>
            </div>
            <div className="styles__Content-sc-19sau32-3 eDClTO">
              <div className="styles__Title-sc-19sau32-9 dHgIqu">
                Enter the recipient’s address
              </div>
              <div className="styles__Description-sc-19sau32-10 ilGpRL">
                The currency you want to receive will be sent to this address
                after the exchange.
              </div>
            </div>
          </div>
          <div className="styles__Card-sc-19sau32-1 gJSzjU">
            <div className="styles__Header-sc-19sau32-2 bLGEnS">
              <div
                color="#0F75FC"
                className="styles__IconWrapper-sc-19sau32-4 fnVpqE"
              >
                <div className="styles__Fireworks-sc-19sau32-6 cRfId">
                  <div className="lf-player-container">
                    <div
                      id="lottie"
                      style={{
                        background: "transparent",
                        margin: "0 auto",
                        outline: "none",
                        overflow: "hidden",
                        width: 120,
                        height: 120,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 500 500"
                        width={500}
                        height={500}
                        preserveAspectRatio="xMidYMid meet"
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: "translate3d(0px, 0px, 0px)",
                          contentVisibility: "visible",
                        }}
                      >
                        <defs>
                          <clipPath id="__lottie_element_238">
                            <rect width={500} height={500} x={0} y={0} />
                          </clipPath>
                          <clipPath id="__lottie_element_240">
                            <path d="M0,0 L500,0 L500,500 L0,500z" />
                          </clipPath>
                          <clipPath id="__lottie_element_244">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_248">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_252">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_256">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_260">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_264">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_268">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_272">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_276">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_280">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_284">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_288">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_292">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_296">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_300">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_304">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                        </defs>
                        <g clipPath="url(#__lottie_element_238)">
                          <g
                            clipPath="url(#__lottie_element_240)"
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <g
                              clipPath="url(#__lottie_element_244)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_248)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_252)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_256)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_260)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_264)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_268)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_272)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_276)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_280)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_284)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_288)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_292)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_296)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_300)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_304)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTExLjg4NyA1LjI4OGwxMC43IDUuMzVjNC44IDIuNCA0LjggNi4zMjQgMCA4LjcyNWwtMTAuNyA1LjM1Yy03LjIgMy42LTEwLjEzNy42NS02LjUzNy02LjUzOGwxLjA4Ny0yLjE2M2MuMjc1LS41NS4yNzUtMS40NjIgMC0yLjAxMkw1LjM1IDExLjgyNWMtMy42LTcuMTg3LS42NS0xMC4xMzcgNi41MzctNi41Mzd6TTYuOCAxNWg2Ljc1IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4="
                  alt="Currency pair"
                  className="styles__Icon-sc-19sau32-5 fgDaFA"
                />
              </div>
              <div className="styles__LineWrapper-sc-19sau32-7 ePLInh">
                <div
                  color="#0F75FC"
                  className="styles__Line-sc-19sau32-8 bojjWg"
                />
              </div>
            </div>
            <div className="styles__Content-sc-19sau32-3 eDClTO">
              <div className="styles__Title-sc-19sau32-9 dHgIqu">
                Send and receive coins
              </div>
              <div className="styles__Description-sc-19sau32-10 ilGpRL">
                To continue, send the indicated amount of coins to the deposit
                address.
              </div>
            </div>
          </div>
          <div className="styles__Card-sc-19sau32-1 gJSzjU">
            <div className="styles__Header-sc-19sau32-2 bLGEnS">
              <div
                color="#104ADE"
                className="styles__IconWrapper-sc-19sau32-4 gXQEgY"
              >
                <div className="styles__Fireworks-sc-19sau32-6 cRfId">
                  <div className="lf-player-container">
                    <div
                      id="lottie"
                      style={{
                        background: "transparent",
                        margin: "0 auto",
                        outline: "none",
                        overflow: "hidden",
                        width: 120,
                        height: 120,
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 500 500"
                        width={500}
                        height={500}
                        preserveAspectRatio="xMidYMid meet"
                        style={{
                          width: "100%",
                          height: "100%",
                          transform: "translate3d(0px, 0px, 0px)",
                          contentVisibility: "visible",
                        }}
                      >
                        <defs>
                          <clipPath id="__lottie_element_356">
                            <rect width={500} height={500} x={0} y={0} />
                          </clipPath>
                          <clipPath id="__lottie_element_358">
                            <path d="M0,0 L500,0 L500,500 L0,500z" />
                          </clipPath>
                          <clipPath id="__lottie_element_362">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_366">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_370">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_374">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_378">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_382">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_386">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_390">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_394">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_398">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_402">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_406">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_410">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_414">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_418">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_422">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_474">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_478">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_482">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_486">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                          <clipPath id="__lottie_element_490">
                            <path d="M0,0 L30,0 L30,30 L0,30z" />
                          </clipPath>
                        </defs>
                        <g clipPath="url(#__lottie_element_356)">
                          <g
                            clipPath="url(#__lottie_element_358)"
                            transform="matrix(1,0,0,1,0,0)"
                            opacity={1}
                            style={{ display: "block" }}
                          >
                            <g
                              clipPath="url(#__lottie_element_362)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_366)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_370)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_374)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_474)"
                              transform="matrix(0,0,0,0,335.9999084472656,486.9996337890625)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <g
                                transform="matrix(1.399935007095337,0.01348781120032072,-0.0164168830960989,1.7039510011672974,-427.5395812988281,-386.6466064453125)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_378)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.399999976158142,0,0,1.7040300369262695,-431.3885803222656,-382.3644714355469)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,99,47)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_382)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_478)"
                              transform="matrix(0,0,0,0,442.999755859375,455.9996337890625)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <g
                                transform="matrix(-1.494289755821228,-0.5186516046524048,0.6312850117683411,-1.8187986612319946,344.2428283691406,604.4989624023438)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_386)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_390)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_394)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.3362121582031,-433.9486389160156)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(242,44,97)"
                                  fillOpacity={1}
                                  d=" M313.197998046875,238.0749969482422 C313.197998046875,238.0749969482422 313.78399658203125,230.5919952392578 313.78399658203125,230.5919952392578 C313.78399658203125,230.5919952392578 317.7309875488281,229.7949981689453 323.5740051269531,228.46600341796875 C323.5740051269531,228.46600341796875 326.78399658203125,236.2480010986328 326.78399658203125,236.2480010986328 C326.78399658203125,236.2480010986328 316.90399169921875,237.66600036621094 313.197998046875,238.0749969482422z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_398)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_402)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_406)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_410)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.5817400217056274,0,0,1.9252400398254395,-489.6051330566406,-435.96630859375)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_482)"
                              transform="matrix(0,0,0,0,499.9996643066406,80.00025939941406)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <g
                                transform="matrix(-0.25562140345573425,-1.560948133468628,1.899932861328125,-0.31113365292549133,-348.49029541015625,585.8517456054688)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(110,44,242)"
                                  fillOpacity={1}
                                  d=" M314.072998046875,237.2760009765625 C314.072998046875,237.2760009765625 313.20001220703125,229.11399841308594 313.20001220703125,229.11399841308594 C313.20001220703125,229.11399841308594 317.4880065917969,230.15499877929688 324.8389892578125,231.66299438476562 C324.8389892578125,231.66299438476562 324.7900085449219,239.36399841308594 324.7900085449219,239.36399841308594 C324.7900085449219,239.36399841308594 316.7090148925781,237.8260040283203 314.072998046875,237.2760009765625z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_414)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_486)"
                              transform="matrix(0,0,0,0,125.00007629394531,2.0003929138183594)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <g
                                transform="matrix(1.301680564880371,-0.012442200444638729,0.015144187025725842,1.5843576192855835,-403.571044921875,-350.49072265625)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_490)"
                              transform="matrix(0,0,0,0,35.00029373168945,456.99969482421875)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <g
                                transform="matrix(1.301680564880371,-0.012442200444638729,0.015144187025725842,1.5843576192855835,-403.571044921875,-350.49072265625)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_418)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              clipPath="url(#__lottie_element_422)"
                              transform="matrix(0,0,0,0,250,250)"
                              opacity={1}
                              style={{ display: "block" }}
                            >
                              <g
                                transform="matrix(1.301740050315857,0,0,1.5844299793243408,-400.0585021972656,-354.47479248046875)"
                                opacity={1}
                                style={{ display: "block" }}
                              >
                                <path
                                  fill="rgb(45,125,255)"
                                  fillOpacity={1}
                                  d=" M312.0790100097656,235.9980010986328 C312.0790100097656,235.9980010986328 310.5740051269531,228.39500427246094 310.5740051269531,228.39500427246094 C310.5740051269531,228.39500427246094 321.8580017089844,227.3300018310547 327.1239929199219,229.90499877929688 C327.1239929199219,229.90499877929688 327.1239929199219,238.20599365234375 327.1239929199219,238.20599365234375 C327.1239929199219,238.20599365234375 319.60101318359375,234.93699645996094 312.0790100097656,235.9980010986328z"
                                />
                              </g>
                            </g>
                            <g
                              transform="matrix(0.021735621616244316,-0.010252575390040874,0.010252575390040874,0.021735621616244316,383.5660400390625,283.144775390625)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <path
                                fill="rgb(242,45,98)"
                                fillOpacity={1}
                                d=" M-96.00700378417969,-40.70600128173828 C-96.00700378417969,-40.70600128173828 -102.54000091552734,-52.790000915527344 -102.54000091552734,-52.790000915527344 C-102.54000091552734,-52.790000915527344 -91.69000244140625,-60.5 -91.69000244140625,-60.5 C-91.69000244140625,-60.5 -85.45999908447266,-49.209999084472656 -85.45999908447266,-49.209999084472656 C-85.45999908447266,-49.209999084472656 -96.00700378417969,-40.70600128173828 -96.00700378417969,-40.70600128173828z"
                              />
                            </g>
                            <g
                              transform="matrix(0.008779393509030342,-0.0041411928832530975,0.0041411928832530975,0.008779393509030342,98.03646087646484,206.0584716796875)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <path
                                fill="rgb(242,45,98)"
                                fillOpacity={1}
                                d=" M-96.00700378417969,-40.70600128173828 C-96.00700378417969,-40.70600128173828 -102.54000091552734,-52.790000915527344 -102.54000091552734,-52.790000915527344 C-102.54000091552734,-52.790000915527344 -91.69000244140625,-60.5 -91.69000244140625,-60.5 C-91.69000244140625,-60.5 -85.45999908447266,-49.209999084472656 -85.45999908447266,-49.209999084472656 C-85.45999908447266,-49.209999084472656 -96.00700378417969,-40.70600128173828 -96.00700378417969,-40.70600128173828z"
                              />
                            </g>
                            <g
                              transform="matrix(0.04061414301395416,0,0,0.04061414301395416,149.81773376464844,350.0713195800781)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <path
                                fill="rgb(45,125,255)"
                                fillOpacity={1}
                                d=" M-96.00700378417969,-40.70600128173828 C-96.00700378417969,-40.70600128173828 -102.54000091552734,-52.790000915527344 -102.54000091552734,-52.790000915527344 C-102.54000091552734,-52.790000915527344 -91.69000244140625,-60.5 -91.69000244140625,-60.5 C-91.69000244140625,-60.5 -85.45999908447266,-49.209999084472656 -85.45999908447266,-49.209999084472656 C-85.45999908447266,-49.209999084472656 -96.00700378417969,-40.70600128173828 -96.00700378417969,-40.70600128173828z"
                              />
                            </g>
                            <g
                              transform="matrix(0.06478185206651688,-0.033513765782117844,0.033513765782117844,0.06478185206651688,320.7987060546875,157.15357971191406)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <path
                                fill="rgb(242,99,47)"
                                fillOpacity={1}
                                d=" M-96.00700378417969,-40.70600128173828 C-96.00700378417969,-40.70600128173828 -102.54000091552734,-52.790000915527344 -102.54000091552734,-52.790000915527344 C-102.54000091552734,-52.790000915527344 -91.69000244140625,-60.5 -91.69000244140625,-60.5 C-91.69000244140625,-60.5 -85.45999908447266,-49.209999084472656 -85.45999908447266,-49.209999084472656 C-85.45999908447266,-49.209999084472656 -96.00700378417969,-40.70600128173828 -96.00700378417969,-40.70600128173828z"
                              />
                            </g>
                            <g
                              transform="matrix(0.053829364478588104,-0.027847686782479286,0.027847686782479286,0.053829364478588104,291.4801940917969,381.12762451171875)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <path
                                fill="rgb(242,99,47)"
                                fillOpacity={1}
                                d=" M-96.00700378417969,-40.70600128173828 C-96.00700378417969,-40.70600128173828 -102.54000091552734,-52.790000915527344 -102.54000091552734,-52.790000915527344 C-102.54000091552734,-52.790000915527344 -91.69000244140625,-60.5 -91.69000244140625,-60.5 C-91.69000244140625,-60.5 -85.45999908447266,-49.209999084472656 -85.45999908447266,-49.209999084472656 C-85.45999908447266,-49.209999084472656 -96.00700378417969,-40.70600128173828 -96.00700378417969,-40.70600128173828z"
                              />
                            </g>
                            <g
                              transform="matrix(0.012436363846063614,0,0,0.012436363846063614,180.1690216064453,181.46731567382812)"
                              opacity={1}
                              style={{ display: "none" }}
                            >
                              <path
                                fill="rgb(110,45,242)"
                                fillOpacity={1}
                                d=" M-96.00700378417969,-40.70600128173828 C-96.00700378417969,-40.70600128173828 -102.54000091552734,-52.790000915527344 -102.54000091552734,-52.790000915527344 C-102.54000091552734,-52.790000915527344 -91.69000244140625,-60.5 -91.69000244140625,-60.5 C-91.69000244140625,-60.5 -85.45999908447266,-49.209999084472656 -85.45999908447266,-49.209999084472656 C-85.45999908447266,-49.209999084472656 -96.00700378417969,-40.70600128173828 -96.00700378417969,-40.70600128173828z"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMzAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkuNDA0IDIyLjkzOGwzLjg3NSAzYy41LjUgMS42MjUuNzUgMi4zNzUuNzVoNC43NWMxLjUgMCAzLjEyNS0xLjEyNSAzLjUtMi42MjVsMy05LjEyNWMuNjI1LTEuNzUtLjUtMy4yNS0yLjM3NS0zLjI1aC01Yy0uNzUgMC0xLjM3NS0uNjI1LTEuMjUtMS41bC42MjUtNGMuMjUtMS4xMjUtLjUtMi4zNzUtMS42MjUtMi43NS0xLS4zNzUtMi4yNS4xMjUtMi43NS44NzVsLTUuMTI1IDcuNjI1IiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiLz48cGF0aCBkPSJNMy4wMyAyMi45Mzh2LTEyLjI1YzAtMS43NS43NS0yLjM3NiAyLjUtMi4zNzZoMS4yNWMxLjc1IDAgMi41LjYyNiAyLjUgMi4zNzZ2MTIuMjVjMCAxLjc1LS43NSAyLjM3NS0yLjUgMi4zNzVINS41M2MtMS43NSAwLTIuNS0uNjI1LTIuNS0yLjM3NXoiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg=="
                  alt="Currency pair"
                  className="styles__Icon-sc-19sau32-5 fgDaFA"
                />
              </div>
              <div className="styles__LineWrapper-sc-19sau32-7 ePLIng">
                <div
                  color="#104ADE"
                  className="styles__Line-sc-19sau32-8 gvVOqt"
                />
              </div>
            </div>
            <div className="styles__Content-sc-19sau32-3 eDClTO">
              <div className="styles__Title-sc-19sau32-9 dHgIqu">
                That’s all!
              </div>
              <div className="styles__Description-sc-19sau32-10 ilGpRL">
                The exchange status “Finished” means that the swap process is
                over.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
