import MainPage from "./pages/MainPage/MainPage";
import ExchangePage from "./pages/ExchangePage/ExchangePage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ExchangeProcessPage from "./pages/ExchangeProcessPage/ExchangeProcessPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import CardPage from "./pages/CardPage/CardPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/exchange",
    element: <ExchangePage />,
  },
  {
    path: "/exchange/process/:id",
    element: <ExchangeProcessPage />,
  },
  {
    path: "/admin",
    element: <AdminPage />,
  },
  {
    path: "/exchange/mercurio",
    element: <CardPage />,
  },
]);

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
