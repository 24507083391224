import React, { useEffect } from "react";
import Navbar from "../../widgets/Navbar/Navbar";
import ExchangeProcess from "../../components/ExchangeProcess/ExchangeProcess";
import Questions from "../../components/Questions/Questions";
import Footer from "../../widgets/Footer/Footer";

const ExchangeProcessPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App styles__Wrapper-sc-gdoee2-0 bKPQhL">
      <Navbar exchange={true} />

      <div className="global__NewContainer-sc-1hzbvz7-1 ePDBUC">
        <div className="styles__ExchangeFormLayout-sc-7wgjik-1 dLeclM">
          <ExchangeProcess />
          <Questions />
        </div>
      </div>

      <div className="styles__Content-sc-gdoee2-2 hKoAua">
        <Footer />
      </div>
    </div>
  );
};

export default ExchangeProcessPage;
