import React from "react";

const BuyWithFiat = () => {
  return (
    <div className="styles__Wrapper-sc-fkoux4-0 eUtMkb">
      <div className="styles__WrapperFlex-sc-fkoux4-1 inCMBS">
        <div className="styles__FiatImg-sc-fkoux4-2 haNxjX" />
        <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
          <div className="styles__TitleName-sc-fkoux4-3 glLYEc">
            Buy crypto with fiat
          </div>
          <div className="styles__PointsList-sc-fkoux4-4 gGndVL">
            <div className="styles__PointItem-sc-fkoux4-5 ioPBYq">
              <div className="styles__CheckImg-sc-fkoux4-6 kQnNmm" />
              <div className="styles__ItemWrap-sc-fkoux4-7 iwnhNQ">
                <div className="styles__ItemName-sc-fkoux4-8 bENcNl">
                  Simple
                </div>
                <div className="styles__ItemDescription-sc-fkoux4-9 bJpkto">
                  Buy 30+ coins using your debit/credit card
                </div>
              </div>
            </div>
            <div className="styles__PointItem-sc-fkoux4-5 ioPBYq">
              <div className="styles__CheckImg-sc-fkoux4-6 kQnNmm" />
              <div className="styles__ItemWrap-sc-fkoux4-7 iwnhNQ">
                <div className="styles__ItemName-sc-fkoux4-8 bENcNl">
                  Profitable
                </div>
                <div className="styles__ItemDescription-sc-fkoux4-9 bJpkto">
                  Benefit from the market-leading rate
                </div>
              </div>
            </div>
            <div className="styles__PointItem-sc-fkoux4-5 ioPBYq">
              <div className="styles__CheckImg-sc-fkoux4-6 kQnNmm" />
              <div className="styles__ItemWrap-sc-fkoux4-7 iwnhNQ">
                <div className="styles__ItemName-sc-fkoux4-8 bENcNl">Safe</div>
                <div className="styles__ItemDescription-sc-fkoux4-9 bJpkto">
                  Receive crypto directly on your wallet
                </div>
              </div>
            </div>
          </div>
          <div style={{ cursor: "pointer" }} role="button">
            <a className="styles__Button-sc-fkoux4-10 iwbRfZ">
              Create fiat exchange
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyWithFiat;
