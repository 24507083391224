import React from "react";
import Cards1 from "../../assets/cards1.png";
import Cards2 from "../../assets/cards2.png";
import Cards3 from "../../assets/cards3.png";
import Cards4 from "../../assets/cards4.png";

const Cards = () => {
  return (
    <div className="styles__Wrapper-sc-1v2fd3l-0 hIgZgr">
      <div className="styles__Cards-sc-1v2fd3l-1 ccotOa">
        <div className="styles__Card-sc-1v2fd3l-2 cWzldy">
          <div className="styles__CardContent-sc-1v2fd3l-3 gKUA-Dd">
            <span className="styles__CardCategory-sc-1v2fd3l-4 cmSJXJ">
              Privacy
            </span>
            <h3 className="styles__CardTitle-sc-1v2fd3l-5 jFceaf">
              Sign-up is not required
            </h3>
            <p className="styles__CardDescription-sc-1v2fd3l-6 bYGWvl">
              SimpleSwap provides cryptocurrency exchange without registration.
            </p>
          </div>
          <div className="styles__CardImageWrapper-sc-1v2fd3l-7 jaYXRK">
            <img
              src={Cards1}
              alt="Sign-up is not required"
              className="styles__CardImage-sc-1v2fd3l-8 llFxPi"
            />
          </div>
        </div>
        <div className="styles__Card-sc-1v2fd3l-2 iRAGzr">
          <div className="styles__CardContent-sc-1v2fd3l-3 gKUA-Dd">
            <span className="styles__CardCategory-sc-1v2fd3l-4 cmSJXJ">
              Wide choice
            </span>
            <h3 className="styles__CardTitle-sc-1v2fd3l-5 jFceaf">
              1000 cryptocurrencies
            </h3>
            <p className="styles__CardDescription-sc-1v2fd3l-6 bYGWvl">
              Hundreds of crypto and fiat currencies are available for exchange.
            </p>
          </div>
          <div className="styles__CardImageWrapper-sc-1v2fd3l-7 jaYXRK">
            <img
              src={Cards2}
              alt="1000 cryptocurrencies"
              className="styles__CardImage-sc-1v2fd3l-8 llFxPi"
            />
          </div>
        </div>
        <div className="styles__Card-sc-1v2fd3l-2 cWzldy">
          <div className="styles__CardContent-sc-1v2fd3l-3 gKUA-Dd">
            <span className="styles__CardCategory-sc-1v2fd3l-4 cmSJXJ">
              24/7 support
            </span>
            <h3 className="styles__CardTitle-sc-1v2fd3l-5 jFceaf">
              You won’t be left alone
            </h3>
            <p className="styles__CardDescription-sc-1v2fd3l-6 bYGWvl">
              Our support team is easy to reach and ready to answer your
              questions.
            </p>
          </div>
          <div className="styles__CardImageWrapper-sc-1v2fd3l-7 jaYXRK">
            <img
              src={Cards3}
              alt="You won’t be left alone"
              className="styles__CardImage-sc-1v2fd3l-8 llFxPi"
            />
          </div>
        </div>
        <div className="styles__Card-sc-1v2fd3l-2 iRAGzr">
          <div className="styles__CardContent-sc-1v2fd3l-3 gKUA-Dd">
            <span className="styles__CardCategory-sc-1v2fd3l-4 cmSJXJ">
              Safety
            </span>
            <h3 className="styles__CardTitle-sc-1v2fd3l-5 jFceaf">
              Non-custodial
            </h3>
            <p className="styles__CardDescription-sc-1v2fd3l-6 bYGWvl">
              Crypto is sent directly to your wallet, we don’t store it on our
              service.
            </p>
          </div>
          <div className="styles__CardImageWrapper-sc-1v2fd3l-7 jaYXRK">
            <img
              src={Cards4}
              alt="Non-custodial"
              className="styles__CardImage-sc-1v2fd3l-8 llFxPi"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
