import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Navbar from './widgets/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Pool from './components/Pool/Pool';
import Cards from './components/Cards/Cards';
import BuyWithFiat from './components/BuyWithFiat/BuyWithFiat';
import Rating from './components/Rating/Rating';
import StartSwap from './components/StartSwap/StartSwap';
import Press from './components/Press/Press';
import Partners from './components/Partners/Partners';
import HowItWorks from './components/HowItWorks/HowItWorks';
import BottomCalc from './components/BottomCalc/BottomCalc';
import Footer from './widgets/Footer/Footer';
import Questions from './components/Questions/Questions';
import RightExchange from './components/RightExchange/RightExchange';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>

    <App />
  </Provider>
);
