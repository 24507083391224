import React, { useEffect, useState } from "react";
import SelectCurrency from "../SelectCurrency/SelectCurrency";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateGet,
  fetchCurrencies,
  fetchFiatCurrencies,
  selectActiveFrom,
  selectActiveTo,
  selectBuySellMode,
  selectCurrencies,
  selectFiat,
  selectGet,
  selectLoading,
  selectSend,
  setActiveFrom,
  setActiveTo,
  setBuySellMode,
  setSendAmount,
  setSwap,
} from "../../features/currencySlice";
import { ReactComponent as Loader } from "../../assets/loading.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ExchangeComponent = () => {
  const [fromVisible, setFromVisible] = useState(false);
  const [toVisible, setToVisible] = useState(false);
  const activeFrom = useSelector(selectActiveFrom);
  const activeTo = useSelector(selectActiveTo);
  const dispatch = useDispatch();
  const send = useSelector(selectSend);
  const get = useSelector(selectGet);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();
  const currencies = useSelector(selectCurrencies);
  const fiatCurrencies = useSelector(selectFiat);
  const isBuySellMode = useSelector(selectBuySellMode);

  useEffect(() => {
    dispatch(fetchCurrencies({ offset: 0, search: "" }));
    dispatch(fetchFiatCurrencies({ offset: 0, search: "" }));
    dispatch(calculateGet());
    // dispatch(setActiveFrom(countries[0]))
    // dispatch(setActiveTo(countries[1]))
  }, []);
  return (
    <div className="styles__Form-sc-14ws4vw-1 ugHpH main-form">
      <div className="styles__Tab-sc-bruegu-0 iOzylm main-page__tabs">
        <div onClick={() => {
          dispatch(setBuySellMode(false))
        }} className={!isBuySellMode ? "styles__TabLeft-sc-bruegu-1 gFRFma" : "styles__TabLeft-sc-bruegu-1 gFRFma kFpiVk"}>
          <span>Crypto Exchange</span>
        </div>
        <div onClick={() => {
          dispatch(setBuySellMode(true))
        }} className={!isBuySellMode ? "styles__TabRight-sc-bruegu-2 FWZYU" : "styles__TabRight-sc-bruegu-2 FWZYU jwSsA-d"}>
          <span>Buy/Sell Crypto</span>
        </div>
      </div>
      <div className="styles__Fields-sc-14ws4vw-2 iuTKaC">
        <div className="styles__Row-sc-9vm97v-0 kHOViF main-page__exchange-group main-page__exchange-from">
          <div className="styles__InputGroup-sc-9vm97v-2 ieTbuU">
            <label
              htmlFor="exchange_form_from_field"
              className="styles__InputLabel-sc-9vm97v-1 jyfsSR"
            >
              You send
            </label>
            <div className="styles__InputContainer-sc-9vm97v-5 imItqd">
              <input
                value={send}
                onChange={(e) => {
                  dispatch(setSendAmount(e.target.value));
                  dispatch(
                    calculateGet()
                  );
                }}
                type="text"
                inputMode="decimal"
                id="exchange_form_from_field"
                className="styles__Input-sc-9vm97v-3 eXBwVq"
                defaultValue="0.1"
              />
            </div>
          </div>
          <div
            onClick={() => setFromVisible(true)}
            className="styles__Layout-sc-9vm97v-10 bEszjz"
          >
            <div className="styles__Icon-sc-9vm97v-12 gnOYfL">
              <img
                alt={activeFrom && activeFrom.cmcTicker}
                src={activeFrom && activeFrom.img}
                className="styles__StyledImage-sc-mte8rl-0 bbwTkW"
              />
            </div>
            <span className="styles__Name-sc-9vm97v-13 hDfsRd">
              {activeFrom && activeFrom.cmcTicker.toUpperCase()}
            </span>
            <div className="styles__Arrow-sc-9vm97v-9 ikkWHY" />
          </div>
          {fromVisible && <SelectCurrency fnc={setFromVisible} />}
        </div>
        <div
          id="form-controls-container"
          className="styles__Controls-sc-1nifg34-0 bBGonL"
        >
          <div className="styles__FloatingRow-sc-1nifg34-3 eVIyoN">
            <div className="styles__FloatingButton-sc-1nifg34-1 elTiF">
              <div className="styles__FloatingIcon-sc-1nifg34-2 jbNTmm main-exchange__floating-icon" />
            </div>
            <div className="styles__FloatingText-sc-1nifg34-4 Ehrph">
              Floating rate
            </div>
          </div>
          <div className="styles__SwapRow-sc-1nifg34-15 dhDeMQ">
            <div
              onClick={() => {
                dispatch(setSwap());
                dispatch(
                  calculateGet()
                );
              }}
              className="styles__SwapIcon-sc-1nifg34-14 cfbzaE"
            />
          </div>
        </div>
        <div className="styles__Row-sc-9vm97v-0 kHOViF main-page__exchange-group main-page__exchange-to">
          <div className="styles__InputGroup-sc-9vm97v-2 jfZjGP">
            {loading ? <Loader /> : ""}
            <label
              htmlFor="exchange_form_to_field"
              className="styles__InputLabel-sc-9vm97v-1 jyfsSR"
            >
              You get
            </label>
            <div className="styles__InputTo-sc-9vm97v-7 gtXlel">
              {loading ? (
                ""
              ) : (
                <div className="styles__InputToTilda-sc-9vm97v-8 dJRMsx" />
              )}
              <span className="styles__InputText-sc-9vm97v-6 hbQfxm">
                {loading ? "" : get}
              </span>
            </div>
          </div>
          <div
            onClick={() => setToVisible(true)}
            className="styles__Layout-sc-9vm97v-10 bEszjz"
          >
            <div className="styles__Icon-sc-9vm97v-12 gnOYfL">
              <img
                alt={activeTo && activeTo.cmcTicker}
                src={activeTo && activeTo.img}
                className="styles__StyledImage-sc-mte8rl-0 bbwTkW"
              />
            </div>
            <span className="styles__Name-sc-9vm97v-13 hDfsRd">
              {activeTo && activeTo.cmcTicker.toUpperCase()}
            </span>
            <div className="styles__Arrow-sc-9vm97v-9 ikkWHY" />
          </div>
          {toVisible && <SelectCurrency isTo={true} fnc={setToVisible} />}
        </div>
        <button
          onClick={() => navigate("/exchange")}
          className="styles__Button-sc-14ws4vw-3 fOUMyi"
        >
          Exchange
        </button>
      </div>
    </div>
  );
};

export default ExchangeComponent;
