import React, { useState } from "react";
import SelectCurrency from "../SelectCurrency/SelectCurrency";
import ExchangeComponent from "../ExchangeComponent/ExchangeComponent";

const Hero = () => {
  return (
    <div className="global__NewContainer-sc-1hzbvz7-1 dHcsbx">
      <div className="styles__Row-sc-gdoee2-3 cHzJyq main-page__exchange-form">
        <h1 className="styles__Title-sc-gdoee2-4 hPKzUi">Crypto Exchange</h1>
        <p className="styles__SubTitle-sc-gdoee2-5 cjggAc">
          Free from sign-up, limits, complications
        </p>
        <div className="styles__Layout-sc-14ws4vw-0 ioaQUX">
          <ExchangeComponent />
        </div>
      </div>
    </div>
  );
};

export default Hero;
