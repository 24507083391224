import React, { useEffect } from "react";
import Navbar from "../../widgets/Navbar/Navbar";
import Hero from "../../components/Hero/Hero";
import Pool from "../../components/Pool/Pool";
import Cards from "../../components/Cards/Cards";
import BuyWithFiat from "../../components/BuyWithFiat/BuyWithFiat";
import Rating from "../../components/Rating/Rating";
import StartSwap from "../../components/StartSwap/StartSwap";
import Press from "../../components/Press/Press";
import Partners from "../../components/Partners/Partners";
import HowItWorks from "../../components/HowItWorks/HowItWorks";
import BottomCalc from "../../components/BottomCalc/BottomCalc";
import Footer from "../../widgets/Footer/Footer";

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App styles__Wrapper-sc-gdoee2-0 kkWwNG">
      <Navbar exchange={false} />

      <div className="styles__Landing-sc-gdoee2-1 iVSFri">
        <Hero />
        <Pool />
        <Cards />
        <BuyWithFiat />
        <Rating />
      </div>

      <div className="styles__Content-sc-gdoee2-2 hKoAua">
        <StartSwap />
        {/* <Press /> */}
        <Partners />
        <HowItWorks />
        {/* <BottomCalc /> */}
        <Footer />
      </div>
    </div>
  );
};

export default MainPage;
