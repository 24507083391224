export const supportedCoins = {
  title: "Supported coins",
  lastWord: "All coins",
  coins: [
    {
      name: "Bitcoin",
    },
    {
      name: "Ethereum",
    },
    {
      name: "Binance Coin",
    },
    {
      name: "Monero",
    },
    {
      name: "Tron",
    },
    {
      name: "Solana",
    },
  ],
};


export const cryptoPairs = {
    title: "Exchange Pairs",
    lastWord: "All Crypto Pairs",
    pairs: [
      {
        coinFrom: "ETH",
        coinTo: "BTC"
      },
      {
        coinFrom: "BTC",
        coinTo: "ETH"
      },
      {
        coinFrom: "BTC",
        coinTo: "USDC"
      },
      {
        coinFrom: "BTC",
        coinTo: "XMR"
      },
      {
        coinFrom: "USDT",
        coinTo: "TRX"
      },
      {
        coinFrom: "ETH",
        coinTo: "BNB"
      }
    ]
  };