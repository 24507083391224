import React from "react";
import PoolImage from "../../assets/pool.png";

const Pool = () => {
  return (
    <div className="styles__Wrapper-sc-1c0l734-0 liTVhk">
      <div className="styles__Block-sc-mte7im-0 kvKKK">
        <img
          alt="new trading contest"
          src={PoolImage}
          className="styles__Image-sc-mte7im-2 egcbae"
        />
        <div className="styles__TextBox-sc-mte7im-3 gsZOTP">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: 10,
            }}
          >
            <img
              alt="new trading contest"
              src={PoolImage}
              className="styles__ImageMob-sc-mte7im-1 fHhNCl"
            />
            <div color="#fff" className="sc-jOrMOR drBPTN">
              Join Our New Trading Contest
            </div>
          </div>
          <div
            color="#fff"
            style={{ marginBottom: 10 }}
            className="sc-jOrMOR cScvJU"
          >
            Winners will share a $12,000 prize pool
          </div>
          <a href="/trading-contest" style={{ textDecoration: "none" }}>
            <button type="button" className="sc-dkzDqf ilLuuq">
              <span>Join now</span>
              <span className="sc-hKMtZM cAWJFe">
                <svg
                  width={20}
                  height={20}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.025 4.942 17.083 10l-5.058 5.058M2.917 10h14.025"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Pool;
